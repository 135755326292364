var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('modal',{ref:"crearServicio",attrs:{"titulo":"Crear servicio","tamano":"modal-lg","adicional":"Crear"},on:{"adicional":_vm.crearServicio}},[_c('ValidationObserver',{ref:"validator"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row justify-content-center f-14"},[_c('div',{staticClass:"col-10"},[_c('p',{staticClass:"input-label-top"},[_vm._v("Taller")]),_c('ValidationProvider',{attrs:{"name":"taller","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('el-select',{staticClass:"w-100",attrs:{"placeholder":"Seleccionar taller","size":"small"},model:{value:(_vm.taller),callback:function ($$v) {_vm.taller=$$v},expression:"taller"}},_vm._l((_vm.select_talleres),function(item){return _c('el-option',{key:item.id,attrs:{"label":item.nombre,"value":item.id}})}),1),_c('vee-error',{attrs:{"text":errors[0]}})]}}])})],1)]),_c('div',{staticClass:"row justify-content-center f-14 my-4"},[_c('div',{staticClass:"col-5"},[_c('p',{staticClass:"input-label-top"},[_vm._v("Configuración")]),_c('ValidationProvider',{attrs:{"name":"configuracion","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('el-select',{staticClass:"w-100",attrs:{"placeholder":"Configuracion","size":"small"},model:{value:(_vm.configuracion),callback:function ($$v) {_vm.configuracion=$$v},expression:"configuracion"}},_vm._l((_vm.select_configuraciones),function(item){return _c('el-option',{key:item.id,attrs:{"label":item.configuracion,"value":item.id}})}),1),_c('vee-error',{attrs:{"text":errors[0]}})]}}])})],1),_c('div',{staticClass:"col-5"},[_c('p',{staticClass:"input-label-top"},[_vm._v("Sección")]),_c('ValidationProvider',{attrs:{"name":"seccion","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('el-select',{staticClass:"w-100",attrs:{"placeholder":"seccion","size":"small"},model:{value:(_vm.seccion),callback:function ($$v) {_vm.seccion=$$v},expression:"seccion"}},_vm._l((_vm.services),function(item){return _c('el-option',{key:item.id,attrs:{"label":item.nombre,"value":item.id}})}),1),_c('vee-error',{attrs:{"text":errors[0]}})]}}])})],1)]),_c('div',{staticClass:"row justify-content-center f-14"},[_c('div',{staticClass:"col-10"},[_c('p',{staticClass:"input-label-top"},[_vm._v("Descripción del servicio")]),_c('ValidationProvider',{attrs:{"name":"descripcion","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('el-input',{staticClass:"w-100",attrs:{"placeholder":"Descripción del servicio","type":"textarea","autosize":{ minRows: 4 }},model:{value:(_vm.inputDescripcion),callback:function ($$v) {_vm.inputDescripcion=$$v},expression:"inputDescripcion"}}),_c('vee-error',{attrs:{"text":errors[0]}})]}}])})],1)])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }