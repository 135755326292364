<template>
    <modal ref="crearServicio" titulo="Crear servicio" tamano="modal-lg" adicional="Crear" @adicional="crearServicio">
        <ValidationObserver ref="validator">
            <div class="container">
                <div class="row justify-content-center f-14">
                    <div class="col-10">
                        <p class="input-label-top">Taller</p>
                        <ValidationProvider v-slot="{ errors }" name="taller" rules="required">
                            <el-select v-model="taller" placeholder="Seleccionar taller" size="small" class="w-100">
                                <el-option v-for="item in select_talleres" :key="item.id" :label="item.nombre" :value="item.id" />
                            </el-select>
                            <vee-error :text="errors[0]"></vee-error>
                        </ValidationProvider>
                    </div>
                </div>
                <div class="row justify-content-center f-14 my-4">
                    <div class="col-5">
                        <p class="input-label-top">Configuración</p>
                        <ValidationProvider v-slot="{ errors }" name="configuracion" rules="required">
                            <el-select v-model="configuracion" placeholder="Configuracion" size="small" class="w-100">
                                <el-option v-for="item in select_configuraciones" :key="item.id" :label="item.configuracion" :value="item.id" />
                            </el-select>
                            <vee-error :text="errors[0]"></vee-error>
                        </ValidationProvider>
                    </div>
                    <div class="col-5">
                        <p class="input-label-top">Sección</p>
                        <ValidationProvider v-slot="{ errors }" name="seccion" rules="required">
                            <el-select v-model="seccion" placeholder="seccion" size="small" class="w-100">
                                <el-option v-for="item in services" :key="item.id" :label="item.nombre" :value="item.id" />
                            </el-select>
                            <vee-error :text="errors[0]"></vee-error>
                        </ValidationProvider>
                    </div>
                </div>
                <div class="row justify-content-center f-14">
                    <div class="col-10">
                        <p class="input-label-top">Descripción del servicio</p>
                        <ValidationProvider v-slot="{ errors }" name="descripcion" rules="required">
                            <el-input v-model="inputDescripcion" placeholder="Descripción del servicio" type="textarea" :autosize="{ minRows: 4 }" class="w-100" />
                            <vee-error :text="errors[0]"></vee-error>
                        </ValidationProvider>
                    </div>
                </div>
            </div>
        </ValidationObserver>
    </modal>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
export default {
    props: {
        id_proyecto: {
            type:  [Number, String],
            default: -1
        }
    },
    data(){
        return {
            taller: '',
            configuracion: '',
            seccion: '',
            inputDescripcion: '',
            inputNombreServicio: '',
        }
    },
    computed: {
        ...mapGetters({
            select_talleres: 'selects/selects/select_talleres',
            select_configuraciones: 'selects/selects/select_configuraciones',
			services: 'configurar/talleres/tipo_servicio/services'
        })
    },
    methods: {
        ...mapActions({
            Action_get_selects_talleres: 'selects/selects/Action_get_selects_talleres',
            Action_get_selects_configuraciones: 'selects/selects/Action_get_selects_configuraciones',
            Action_create_servicio: 'obras/servicios/services/Action_create_servicio',
            Action_get_servicios: 'obras/servicios/services/Action_get_servicios'
        }),
        toggle(){
            this.$refs.validator.reset()
            this.taller = ''
            this.configuracion = ''
            this.seccion = ''
            this.inputDescripcion = ''
            this.$refs.crearServicio.toggle()
        },
        async crearServicio(){
            const validate = await this.$refs.validator.validate()
            if (!validate) return
            const payload = {
                id_almacen_etapas_configuraciones_secciones: this.seccion,
                id_taller: this.taller,
                descripcion: this.inputDescripcion,
                id_proyecto: this.id_proyecto
            }
            await this.Action_create_servicio(payload)
            await this.Action_get_servicios({ id_proyecto: this.id_proyecto })
            this.$refs.crearServicio.toggle()
        }
    }
}
</script>

<style lang="css" scoped>
</style>
